<template>
    <div class="page">
        <el-form size="small" :inline="true" ref="searchForm" class="query-form" :model="searchForm" @keyup.enter.native="refreshList(1)">
            <el-form-item prop="roleId" label="角色名称：">
                <el-select v-model="searchForm.roleId" filterable placeholder="请选择" clearable>
                    <el-option v-for="item in roleOpt" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button v-if="hasPermission('sys:temporary:add')" type="primary" size="small" icon="el-icon-plus"
                           @click="addEdit('add', '')">新增
                </el-button>
                <el-button v-if="hasPermission('sys:temporary:del')" type="danger" size="small" icon="el-icon-delete"
                           @click="del()">批量删除
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 298px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="roleNames"
                        label="角色名称">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" v-if="hasPermission('sys:temporary:edit')"
                                 @click="addEdit('edit', scope.row.id)">{{scope.row.roleNames}}
                        </el-link>
                        <span v-else>{{scope.row.roleNames}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        show-overflow-tooltip
                        prop="effectiveTime"
                        label="有效时间">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="400"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('sys:temporary:data')" type="text"
                                   size="mini"
                                   @click="dataAuthor(scope.row)">数据权限
                        </el-button>
                        <el-button v-if="hasPermission('sys:temporary:auth')" type="text"
                                   size="mini" @click="showAuth(scope.row)">功能权限
                        </el-button>
                        <el-button v-if="hasPermission('sys:temporary:edit')" type="text"
                                   size="mini"
                                   @click="addEdit('edit', scope.row.id)">修改
                        </el-button>
                        <el-button v-if="hasPermission('sys:temporary:del')" type="text"
                                   size="mini"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background=""
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <AuthForm ref="authForm"></AuthForm>
            <DataForm ref="dataForm"></DataForm>
            <TemporaryForm ref="temporaryForm" @refreshDataList="refreshList"></TemporaryForm>
        </div>
    </div>
</template>
<script>
    import TemporaryForm from './temporaryForm'
    import AuthForm from '../role/AuthForm'
    import DataForm from '../role/DataForm'

    export default {
        data() {
            return {
                searchForm: {
                    roleId: '',
                    current: 1,
                    size: 10,
                },
                roleOpt: [],
                dataList: [],
                total: 0,
                dataListSelections: [],
                loading: false
            }
        },
        components: {
            AuthForm,
            DataForm,
            TemporaryForm
        },
        activated() {
            this.getSysRole()
            this.refreshList(1)
        },
        methods: {
            // 获取数据列表
            refreshList(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.user.systemporaryauthorityList, this.searchForm, 'get').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 获取角色
            getSysRole() {
                this.$axios(this.api.user.getSysTemporaryAuthorityRole).then(data => {
                    if (data.status) {
                        this.roleOpt = data.data
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.refreshList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 新增，修改，查看
            addEdit(method, id) {
                this.$refs.temporaryForm.init(method, id)
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗？如删除数据，权限还未到期，则表示提前收回全部临时权限。`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.user.systemporaryauthorityRmoveById, ids, 'post').then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        }
                    })
                })
            },
            // 重置
            resetSearch() {
                this.$refs.searchForm.resetFields()
                this.currentChangeHandle(1)
            },
            // 角色授权
            showAuth(row) {
                this.$refs.authForm.init(row.id, 'temporary')
            },
            // 数据权限
            dataAuthor(row) {
                this.$refs.dataForm.init(row.id, 'temporary')
            },
        }
    }
</script>
